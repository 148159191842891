import React from "react"
import styles from "../styles/index.module.css"
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import Logo from "../components/logo"

export default function Navbar() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))
  const matchesXS = useMediaQuery("(max-width:370px)")
  return (
    <AppBar position="sticky" className={styles.appBar}>
      <Toolbar>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <div className={!matchesXS && styles.navContainerRight}>
              <Logo />
            </div>
          </Grid>
          <Grid item>
            <Grid container justify="flex-end" alignItems="center">
              <Grid item>
                <IconButton edge="start" aria-label="menu" size="medium">
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
