import React from "react"
import styles from "../styles/index.module.css"
import { Link } from "gatsby"
import Logo from "./logo"
import {
  IconButton,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
export default function Footer() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))
  return (
    <>
      <footer className={styles.footer}>
        <Grid
          container
          justify={matches ? "space-between" : "center"}
          alignItems="center"
          direction={matches ? "row" : "column"}
        >
          <Grid item>
            <Logo />
          </Grid>
          <Grid item>
            <Link to="/imprint">
              <Button>Impressum</Button>
            </Link>
            <a href="https://firebasestorage.googleapis.com/v0/b/ps-c-b11af.appspot.com/o/AGB%20PS%20Consulting%20v1.1%209_20.pdf?alt=media&token=f7dc62d9-ca77-47b3-b2c8-31aa7f8a5d36">
              <Button>AGB</Button>
            </a>
            <Link to="/datenschutz">
              <Button>Datenschutz</Button>
            </Link>
            <Link to="/contact">
              <Button>Kontakt</Button>
            </Link>
            <a href="https://linkedin.com/in/ps206">
              <IconButton>
                <LinkedInIcon />
              </IconButton>
            </a>
          </Grid>
        </Grid>
      </footer>
    </>
  )
}
